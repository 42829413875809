<template>
  <div>
    <div class="theme--tuktuk__modal__container"
      :class="{ 'theme--tuktuk__modal__container--no-padding': data.multiplyVariations && !data.cartOrder }">
      <div class="tuktuk-modal-variant__cart-info"
        v-if="data.type != 'simple' && data.multiplyVariations && data.cartOrder">
        <div class="tuktuk-modal-variant__cart-info__container">
          <div class="tuktuk-modal-variant__cart-info__add">
            <img src="@/assets/icons/TuktukTheme/cart--pink.svg" />
            <p class="tuktuk-modal-variant__cart-info__text-label">{{ $t("tuktukTheme.Basket") }}</p>
            <p class="tuktuk-modal-variant__cart-info__text-number">({{ data.cartSummary.reduce((a, b) => a + (b.qty), 0)
              || data.cartQuantity }})</p>
          </div>
          <div class="tuktuk-modal-variant__cart-info__total">
            <p class="tuktuk-modal-variant__cart-info__text-label">{{ $t("common.total") }}</p>
            <p class="tuktuk-modal-variant__cart-info__text-number">
              Rp{{
                (data.cartSummary.length > 0 && cartTotalPrice
                  ? cartTotalPrice
                  : data.totalPrice) | g_number_format
              }}
            </p>
          </div>
        </div>
      </div>    
      <!-- <hr> -->
      <!-- PRODUCT IMAGE -->
      <div v-if="selectedVariantIndex > -1" class="pp-modal-choose-variant--mobile__thumbnail">
        <template v-if="data.showProductImages && data.productImages.length > 0">
          <!-- PRODUCT IMAGE SINGLE -->         
            <div class="pp-modal-choose-variant--mobile__thumbnail__single">
              <img :alt="data.variablePrices[selectedVariantIndex].image" :src="getImageSrc(data.variablePrices[selectedVariantIndex])" />
            </div>           
        </template>
        <div class="pp-modal-choose-variant--mobile__thumbnail__content">
          <div class="pp-modal-choose-variant--mobile__thumbnail__content__number">
            <div class="theme--tuktuk__product-details__price">
              <template v-if="data.variablePrices[selectedVariantIndex] &&
                (!data.multiplyVariations ||
                  (data.multiplyVariations && data.cartOrder))
                ">
                <template v-if="data.variablePrices[selectedVariantIndex].priceCompare > 0 &&
                  data.variablePrices[selectedVariantIndex].priceCompare !==
                  data.variablePrices[selectedVariantIndex].price
                  ">
                  <h2 class="theme--tuktuk__product-details__price__sales">
                    Rp{{ data.variablePrices[selectedVariantIndex].priceCompare | g_number_format
                    }}

                  </h2>
                  <div class="theme--tuktuk__product-details__price__container-sales">
                    <p class="theme--tuktuk__product-details__price__regular">
                      <template>
                        Rp{{ (data.variablePrices[selectedVariantIndex].price) | g_number_format }}
                      </template>
                    </p>
                  </div>
                </template>
                <template v-else>
                  <h2 class="theme--tuktuk__product-details__price__regular">
                    Rp{{ (data.variablePrices[selectedVariantIndex].price) | g_number_format }}
                  </h2>
                </template>
              </template>
              <template v-else>
                <template v-if="data.variablePrices[selectedVariantIndex] && data.variablePrices[selectedVariantIndex].priceCompare > 0 &&
                  data.variablePrices[selectedVariantIndex].priceCompare !==
                  data.variablePrices[selectedVariantIndex].price
                  ">
                  <h2 class="theme--tuktuk__product-details__price__sales">
                    Rp{{ data.variablePrices[selectedVariantIndex].priceCompare | g_number_format
                    }}

                  </h2>
                  <div class="theme--tuktuk__product-details__price__container-sales">
                    <p class="theme--tuktuk__product-details__price__regular">
                      <template>
                        Rp{{ (data.variablePrices[selectedVariantIndex].price) | g_number_format }}

                      </template>
                    </p>
                  </div>
                </template>
                <template v-else>
                  <h2 class="theme--tuktuk__product-details__price__regular" v-if="data.variablePrices[selectedVariantIndex]">
                    Rp{{ (data.variablePrices[selectedVariantIndex].price) | g_number_format }}
                  </h2>
                </template>
              </template>


            </div>
          </div>
          <p v-if="data.multiplyVariations && !data.cartOrder && selectedVariantIndex > -1"
            class="pp-modal-choose-variant--mobile__thumbnail__content__variant">
            {{
              data.variablePrices[selectedVariantIndex].attrs.map(x => x.value).join(" - ")
            }}
          </p>
          <p v-else class="pp-modal-choose-variant--mobile__thumbnail__content__variant">
            {{
              Object.keys(this.payload.variablePrices)
                .map((o) => this.payload.variablePrices[o].name)
                .join(" - ")
            }}
          </p>
        </div>
      </div>

      <div class="theme--tuktuk__modal__main">
        <template v-if="data.multiplyVariations &&
            !data.cartOrder
            ">


          <div  @click="chageIndex(attr)"  :key="'variant' + i" v-for="(attr, i) in data.variablePrices" class="variant-option-qty"
          :class="{ 'variant-option-qty--selected': i === selectedVariantIndex,'mb-10': (i != data.variablePrices.length - 1) && !attr.delete, 'variant-option-qty--no-border': attr.delete, 'p-0': attr.delete }">
            <template v-if="!attr.delete">
              <div class="variant-option-qty__content" :class="data.isShowVariantImage && (attr.image || data.productImages.length > 0)
                  ? 'variant-option-qty__content--show-thumbnail'
                  : ''
                ">
                <div v-if="data.isShowVariantImage && (attr.image || data.productImages.length > 0)"
                  class="variant-option-qty__content--show-thumbnail__thumbnail">
                  <img alt="" :src="getImageSrc(attr)"/>
                </div>
                <div>
                  <p class="variant-option-qty__content__label">
                    {{ attr.name }}
                  </p>
                  <!-- <p v-if="attr.qty" class="tuktuk-modal-variant__cart-label">
                    <img src="@/assets/icons/TuktukTheme/cart--pink.svg" />
                    <span>{{ $t("tuktukTheme.addedToCart") }}</span>
                  </p> -->
                </div>
              </div>
              <div class="action-qty">
                <button @click="decrement(i, 'multipleVariant')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="2" viewBox="0 0 11 2" fill="none">
                    <path opacity="0.2" d="M0.5 1H10.5" stroke="black" />
                  </svg>
                </button>
                <vs-input readonly min="0" v-model="attr.qty" />
                <button @click="increment(i, 'multipleVariant')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M0.874084 6H11.1259" stroke="black" />
                    <path d="M6 0.874146L6 11.1259" stroke="black" />
                  </svg>
                </button>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div>
            <div v-if="data.attributes">
              <div v-for="(attr, i) in data.attributes" v-bind:key="i" class="text-left"
                :class="{ 'mb-15': i != data.attributes.length - 1 }">
                <label class="text--label-field mb-10">{{ attr.name }}</label>
                <div class="flex flex-wrap gap-15 variant-selected-btn">
                  <div v-for="(attrval, j) in filteredProductVariant(attr.value, attr.name)" v-bind:key="j">
                    <div class="above-switch position-relative">
                      <selected-green-switch :className="'tuktukTheme'" v-if="data.payload.variablePrices[
                        attr.name + i.toString()
                        ]
                        " v-model="data.payload.variablePrices[
      attr.name + i.toString()
    ].name
    " :options="[
    {
      id: attrval,
      text: attrval,
      imgSrc: undefined,
      disabled:checkOption(attr.name,attrval),
      hideUnavailableVariant: data.hideUnavailableVariant
    },
  ]" full="99"></selected-green-switch>
                      <!-- <div v-if="i == 0 && (data.payload.variablePrices[attr.name + i.toString()].name == attrval) && data.cartOrder" class="selected-variant-qty-counter">
                          <img src="@/assets/icons/TuktukTheme/cart--white.svg" alt="" />
                          <div class="qty-counter-value" v-if="selectedVariantAddedQty">{{selectedVariantAddedQty}}</div>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template v-if="data.addQuantity &&
        ((data.multiplyVariations && data.cartOrder) ||
          !data.multiplyVariations)
        ">
        <div class="add-quantity--tuktuk">
          <label>{{ $t("common.quantity") }}</label>
          <div class="action-qty">
            <button @click="decrement(0, 'singleVariant')">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="2" viewBox="0 0 11 2" fill="none">
                <path opacity="0.2" d="M0.5 1H10.5" stroke="black" />
              </svg>
            </button>
            <vs-input min="0" v-model="data.cartQuantity" />
            <button @click="increment(0, 'singleVariant')">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M0.874084 6H11.1259" stroke="black" />
                <path d="M6 0.874146L6 11.1259" stroke="black" />
              </svg>
            </button>
          </div>
        </div>
      </template>

      <div v-if="data.variablePrices.length > 0 && !data.isVariableItemSelected"
        class="alert-warning1 mb-20 title-font error align-items-start d-flex">
        <img class="mr-10" src="@/assets/icons/error.svg" />
        {{ $t("tuktukTheme.variationCombinationNotAvailable") }}
      </div>

      <div class="theme--tuktuk__modal__footer">
        <button v-if="data.cartOrder && data.multiplyVariations && data.tuktukThemeSetting.TuktukSettingsAddTOCart"
          class="pp-button-pink--border" :disabled="data.cartQuantity == 0 ||
            (this.data.variablePrices.length > 0 &&
              !data.isVariableItemSelected)
            " @click="addToCart('update')">
          <span>{{ $t("common.addToCart") }}</span>
        </button>

        <button @click="setStep(2)" class="buy-button__default pp-button-pink--fill text-white" :disabled="(this.data.variablePrices.length > 0 &&
            !data.isVariableItemSelected) || (this.data.multiplyVariations && !this.data.cartOrder && this.isAnyVariantwithQtyOne.length < 1)
          " :class="this.data.variablePrices.length > 0 &&
      !data.isVariableItemSelected
      ? 'buy-button__default--disabled'
      : ''
    ">
          <span>{{ $t("tuktukTheme.confirm") }}</span>
        </button>
      </div>
      <transition name="fade" v-on:enter="showMsg">
        <div v-if="data.show" class="variant-modal__alert">
          <div class="theme--tuktuk__toast">
            <div class="theme--tuktuk__toast__content">
              <img src="@/assets/icons/TuktukTheme/cart--pink-circle.svg" />
              <p>
                {{ $t("tuktukTheme.addedToCart") }}
              </p>
            </div>
          </div>
          <!-- <div class="variant-modal__alert__content">
              <img src="@/assets/icons/grey-tick.svg" alt="" />
              <div>{{ $t("common.itemAddedToCart") }}</div>
            </div> -->
        </div>
      </transition>

      <!-- <div class="theme--tuktuk__toast">
          <div class="theme--tuktuk__toast__content">
            <img src="@/assets/icons/TuktukTheme/cart--pink-circle.svg"/>
            <p>Logitech Futuristic Arcade keyboard - Special Edition - Wireless telah ditambahkan ke keranjang</p>
          </div>
        </div> -->
    </div>
  </div>
</template>
<script>
const SwitchGreenSelected = (() => import('@/components/SwitchGreenSelected.vue'))
import config from "./../../../../config.js";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  props: ["data", "payload", "isVariantModalOpen","itemIdex"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      show: false,
      chartModal: false,
      chartModalEmbed: false,
      selectedVariantAddedQty: 1,
      selectedVariantIndex: 0,
      isAnyVariantwithQtyOne: this.data.variablePrices.filter((e) => e.qty > 0),
      carouselSetting: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 6,
            },
          },
        ],
      },
      getSizedImageUrl,
    };
  },
  components: {
    "selected-green-switch": SwitchGreenSelected,
  },
  methods: {
    chageIndex(variant) {
      if ((this.data.multiplyVariations && !this.data.cartOrder) && variant) {
       // let variablePricesName = Object.keys(val).map(o => val[o].name).join(' / ');       
        this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === variant.name);
      }
    },
    setStep(count) {
      this.$emit("setStep", count);
    },
    getImageSrc(attr){  
        const variantImageIndex = this.data.variablePrices && this.data.variablePrices.findIndex(
            (x) => x.name  && x.image && x.name.indexOf(attr.attrs[0].value+" / ") > -1
          );
        if(attr.image) return getSizedImageUrl(attr.image,'medium','product-images');
        else if (variantImageIndex > -1  && this.data.variablePrices && this.data.variablePrices[variantImageIndex].image) return getSizedImageUrl(this.data.variablePrices[variantImageIndex].image,'medium','product-images'); 
        else if (this.data.productImages.length>0 ) return getSizedImageUrl(this.data.productImages[0].src,'medium','product-images'); 
        else return require('@/assets/icons/variant-default-img.svg');   
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    checkOption(name, attrval) {
      if (this.data.filterAttributes) {
        return !this.data.filterAttributes[name + "_" + attrval];
      } else {
        return false;
      }
    },
    getSubPriceCart(attr, cartQty) {
      let price = 0;
      if (cartQty && attr) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(cartQty)) {
              subPrice = one.price;
            }
          });
        }

        price = price + subPrice * cartQty;
      }

      return price;
    },
    increment(val, type) {
      this.$emit("increment", val, type);
    },
    decrement(val, type) {
      this.$emit("decrement", val, type);
    },
    showMsg() {
      this.$emit("showMsg");
    },

    addToCart() {
      this.$emit("addToCart");
    },
    updateCartQuantity() {
      this.$emit("updateCartQuantity", this.data.cartQuantity);
    },
    addCartItem() {
      this.$emit("addCartItem");
    },
    getSelectedVariantName(param) {
      let selectedVariant = Object.values(param);
      selectedVariant = selectedVariant.map((data) => {
        return data.name;
      });
      return selectedVariant
        .toString()
        .replace(/[,]/g, " / ");
    },
    shouldDisplayProductVariantOnCheckout(name, attrValue) {
      return !this.data.hideUnavailableProductVariant || (this.data.hideUnavailableProductVariant && !this.checkOption(name, attrValue));
    },
    filteredProductVariant(attrValues, attrName) {
      return attrValues.filter(attrValue => this.shouldDisplayProductVariantOnCheckout(attrName, attrValue));
    }
  },
  watch: {
    chartModal: {
      handler(val) {
        this.$emit("changeVariant", val);
      },
    },
    chartModalEmbed: {
      handler(val) {
        this.$emit("changeVariant", val);
      },
    },
    isVariantModalOpen: {
      handler(val) {
        this.$emit("changeVariant", val);
      },
    },
    "data.changeVariant": {
      handler(val) {
        this.chartModal = val;
        this.chartModalEmbed = val;
        if (this.data.changeVariantItem && this.data.changeVariantItem.items) {
          Object.keys(this.data.changeVariantItem.items).map((key) => {
            this.data.payload.variablePrices[key].name =
              this.data.changeVariantItem.items[key].name;
          });
        }
        // this.$emit("changeVariant", val);
      },
    },
    "data.variablePrices": {
      handler(val) {
        this.isAnyVariantwithQtyOne = val.filter((e) => e.qty > 0);
      },
    },
    "data.payload.variablePrices": {
      handler(val) {      
         let variablePricesName = Object.keys(val).map(o => val[o].name).join(' / ');       
        this.selectedVariantAddedQty = this.data.cartSummary.find(item => item.name === variablePricesName) && this.data.cartSummary.find(item => item.name === variablePricesName).qty || 1;
        if(!this.data.multiplyVariations || (this.data.multiplyVariations && this.data.cartOrder)){
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === variablePricesName);
        }       

        let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
          if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder && !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ){
          let priceVar = this.data.cartSummary.find(
            (e) => e.name == selectedVariant
          );
          this.data.cartQuantity=priceVar && priceVar.qty ? priceVar.qty: !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ? 0 : 1;               
        } 
      },
    },
  },
  computed: {
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },
  mounted(){
    if(this.itemIdex && this.itemIdex > -1){
      this.selectedVariantIndex=this.itemIdex;
    }
   
  }
};
</script>
    
<style lang="scss">
.select-option-with-image-wrapper {
  .slick-list {
    padding: 4px 16px;
  }

  .slick-slide {
    width: fit-content !important;
    margin-right: 10px;
  }
}

.select-options-with-no-image-itemName {
  border-radius: 2px;
  border: 1px solid #eb435b;
  display: flex;
  color: #eb435b;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: fit-content;
}

.selected-variant-qty-counter {
  display: flex;
  height: 24px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;
  right: -19px;
  top: -12px;
  border-radius: 10px;
  background: #eb435b;
  z-index: 9;

  .qty-counter-value {
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.2px;
  }
}

.select-options--tuktuk {
  margin-bottom: 8px;
  background-color: #ffffff;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer;

  .select-options--tuktuk__button {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 8px;

    .select-options--tuktuk__button__right-side {
      display: flex;
      align-items: center;

      span {
        color: #15171c;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 16px */
        letter-spacing: 0.2px;
        opacity: 0.4;
      }
    }
  }

  .select-options--tuktuk__thumbnails {
    padding-left: 15px;

    .slick-list {
      .slick-slide {
        width: 32px !important;
        height: 32px;
        margin-right: 8px;
        background-color: #f9f7fa;

        &>div {
          height: 100%;
        }
      }

      .select-options--tuktuk__thumbnail {
        height: 100%;
        display: flex;
        align-content: center;

        img {
          border-radius: 4px;
          object-fit: cover;
          width: 100%;
          height: 100%;
          cursor: grab;

          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }
  }
}

.theme--tuktuk__modal.tuktuk-modal-variant {
  &.vs-dialog-content {
    .vs-dialog {
      .vs-dialog__close {
        top: 15px !important;
        right: 15px !important;
        display: none;

        .vs-icon-close {
          width: 24px;
          height: 24px;

          &:before,
          &:after {
            top: 12px;
            bottom: 12px;
            left: 6px;
            right: 6px;
          }
        }
      }

      .vs-dialog__content {
        .theme--tuktuk__modal__container {
          padding: 0;
          padding-top: 15px;

          .alert-warning1 {
            margin: 0 15px;
          }

          hr {
            margin: 15px 20px 20px;

            &.theme--tuktuk__modal__line-last {
              display: none;
            }

            &.theme--tuktuk__modal__line-w-100 {
              margin-right: 0;
              margin-left: 0;
            }
          }

          .theme--tuktuk__modal__main {
            overflow-y: auto;
            padding: 15px !important;
            // max-height: 400px;

            @media screen and (max-width: 575px) {
              padding: 15px !important;
            }
          }

          .theme--tuktuk__modal__footer {
            display: flex;
            width: 100%;
            padding: 15px;
            gap: 15px;
            background: #ffffff;
            box-shadow: 0px -14px 30px rgba(0, 0, 0, 0.05);

            .pp-button-pink--border,
            .pp-button-pink--fill {
              border-radius: 2px;
              width: 100%;
              flex: 1;
              padding: 10px;
              font-family: TikTokDisplayMedium;
            }
          }
        }
      }
    }
  }

  &.tuktuk-modal-variant--show-thumbnail {
    .vs-dialog {
      .vs-dialog__close {
        top: 19px !important;
      }

      .vs-dialog__content .theme--tuktuk__modal__container {
        padding: 0;

        .theme--tuktuk__modal__main {
          padding: 15px !important;

          @media screen and (max-width: 575px) {
            padding: 15px !important;
          }
        }
      }
      .pp-modal-choose-variant--mobile__thumbnail {
        grid-template-columns: 96px 1fr;
        padding-top: 15px;
      }
    }   
  }

  
  &.select-options--tuktuk-modal--show-thumbnail{
    .pp-modal-choose-variant--mobile__thumbnail {
      grid-template-columns: 96px 1fr;
      padding-top: 15px;
    }
  }




  .pp-modal-choose-variant--mobile__thumbnail {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 15px;
    padding-top: 0;
    border-bottom: 1px solid rgba(161, 162, 164, 0.2);

    .pp-modal-choose-variant--mobile__thumbnail__single {
      width: 96px;
      border-radius: 8px;
      border: 1px solid #bfcee0;
      background-color: #f6f8fc;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .pp-modal-choose-variant--mobile__thumbnail__carousel {
      min-width: 96px;
      min-height: 96px;
      background-color: #f6f8fc;
      border-radius: 8px;
      border: 1px solid #bfcee0;

      .slick-slider {
        width: 96px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #bfcee0;
        background-color: #f6f8fc;

        img {}

        .slick-slide {
          height: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .slick-dots,
        .slick-prev,
        .slick-next {
          display: none !important;
        }
      }
    }

    .pp-modal-choose-variant--mobile__thumbnail__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      row-gap: 10px;
      padding-right: 20px;

      p,
      h2 {
        margin: 0;
        text-align: left;
      }

      .pp-modal-choose-variant--mobile__thumbnail__content__number {
        .theme--tuktuk__product-details__price {
          @mixin init-price {
            font-size: 20px;
            font-style: normal;
            font-family: TikTokDisplayBold;
            font-weight: 700;
            line-height: 160%;
            /* 28.8px */
            letter-spacing: 0.36px;
            margin: 0;
          }

          .theme--tuktuk__product-details__price__regular {
            @include init-price;
            color: #15171c;
          }

          .theme--tuktuk__product-details__price__sales {
            @include init-price;
            color: #eb435b;
          }

          .theme--tuktuk__product-details__price__container-sales {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .theme--tuktuk__product-details__price__regular {
              font-size: 14px;
              font-family: TikTokDisplayMedium;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              /* 19.2px */
              letter-spacing: 0.24px;
              text-decoration: line-through;
              margin: 0;
              opacity: 0.4;
            }
          }
        }

        overflow-wrap: anywhere;

        .pp-modal-choose-variant--mobile__thumbnail__content__number__price {
          color: #203551;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          /* 133.333% */
          letter-spacing: 0.36px;
        }

        .pp-modal-choose-variant--mobile__thumbnail__content__before-discount {
          color: #fa5757;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.24px;
          text-decoration: line-through;
        }
      }

      .pp-modal-choose-variant--mobile__thumbnail__content__variant {
        color: #6c7e95;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 120% */
        letter-spacing: 0.3px;
        text-transform: capitalize;
      }
    }
  }

  .tuktuk-modal-variant__cart-info {
    padding: 15px 15px 0;

    .tuktuk-modal-variant__cart-info__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-radius: 2px;
      background: #fce3e6;
      width: 100%;
      min-height: 32px;
      padding: 4px 5px;
    }

    .tuktuk-modal-variant__cart-info__text-number {
      color: #15171c;
      font-family: TikTokDisplayMedium;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      /* 22.4px */
      letter-spacing: 0.28px;
      margin: 0;
    }

    .tuktuk-modal-variant__cart-info__text-label {
      color: #15171c;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      /* 22.4px */
      letter-spacing: 0.28px;
      margin: 0;
    }

    .tuktuk-modal-variant__cart-info__add {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .tuktuk-modal-variant__cart-info__total {
      display: flex;
      gap: 8px;

      .tuktuk-modal-variant__cart-info__text-label {
        opacity: 0.4;
      }

      .tuktuk-modal-variant__cart-info__text-bold {
        overflow-wrap: anywhere;
      }
    }
  }

  .add-quantity--tuktuk {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    padding: 10px 15px;

    &>label {
      color: #15171c;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      /* 19.2px */
      letter-spacing: 0.24px;
      font-family: TikTokDisplayMedium;
    }
  }

  .tuktuk-modal-variant__option-qty {
    border-radius: 8px;
    border: 1px solid #bfcee0;
    background: #fff;
    padding: 5px 5px 5px 10px;
    display: grid;
    grid-template-columns: 1fr 120px;
    gap: 10px;
    align-items: center;
    flex: 1;
    width: 100%;
    grid-template-columns: 1fr 80px;
    border-radius: 4px;
    border: 1px solid rgba(21, 23, 28, 0.1);
    cursor: pointer;

    .tuktuk-modal-variant__option-qty__content {
      flex: 1;
      justify-content: flex-start;
      text-align: left;

      &.tuktuk-modal-variant__option-qty__content--show-thumbnail {
        display: grid;
        grid-template-columns: 32px 1fr;
        gap: 10px;
        align-items: center;

        .tuktuk-modal-variant__option-qty__content--show-thumbnail__thumbnail {
          height: 32px;
          border-radius: 4px;
          background-color: rgba(21, 23, 28, 0.1);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .tuktuk-modal-variant__option-qty__content__label {
        color: #15171c;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 22.4px */
        letter-spacing: 0.28px;
        margin: 0;
      }
    }

    .tuktuk-modal-variant__option-qty__action {
      display: grid;
      grid-template-columns: 32px 1fr 32px;
      height: 43px;
      gap: 5px;
      background: #eaedf8;
      padding: 5px 4px;
      border-radius: 8px;

      button {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        border-radius: 8px;
        background: #ffffff;
        cursor: pointer;
      }

      .tuktuk-modal-variant__option-qty__action__number {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }

    &.tuktuk-modal-variant__option-qty--selected {
      border-color: #eb435b;

      .tuktuk-modal-variant__option-qty__content {
        .tuktuk-modal-variant__option-qty__content__label {
          color: #eb435b;
        }
      }
    }

    &.tuktuk-modal-variant__option-qty--no-border {
      border: 0 !important;
    }

    &.p-0 {
      padding: 0 !important;
    }
  }
}

.theme--Tuktuk-embed {
  .theme--tuktuk__modal {
    position: absolute !important;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -999 !important;
    border-radius: 0;
    overflow: hidden;
    border-radius: 0px;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.theme--tuktuk__modal--show {
      display: flex;
      align-items: stretch;
      visibility: visible;
      z-index: 9999 !important;

      .theme--tuktuk__modal__overflow {
        display: inline-block;
        opacity: 1;
      }

      .theme--tuktuk__modal__container {
        transition: all 300ms ease;
        transform: translateY(0);
      }
    }

    .theme--tuktuk__modal__button-close {
      position: absolute;
      top: 15px;
      right: 15px;
      background: transparent;
      border: none;
      outline: none;
      z-index: 99;
    }

    .theme--tuktuk__modal__overflow {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: all 300ms ease;
    }

    .theme--tuktuk__modal__container {
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      background: #fff;
      padding: 0;
      padding-top: 30px;
      transform: translateY(100%);
      transition: all 300ms ease;

      .alert-warning1 {
        margin: 0 15px;
      }

      .theme--tuktuk__modal__main {
        padding: 15px;
        // max-height: 400px;
        overflow-y: auto;
      }

      .theme--tuktuk__modal__footer {
        display: flex;
        gap: 15px;
        padding: 10px;

        button {
          width: 100%;
        }
      }
    }
  }
}

.withItemOnly {
  .slick-track {
    width: 100% !important;
    display: flex;
    align-items: center;
  }

  .select-options-with-no-image-itemName {
    width: max-content;
  }
}
</style>
    
