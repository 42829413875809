<template>
  <div class="card-shop-discount" v-if="data.payload.discount.code || data.payload.automaticDiscount.code">
    <div class="card-shop-discount__header">
      <img src="@/assets/icons/TuktukTheme/shop-discount.svg" alt="icon" style="width: 20px; height: 20px;">
      <h2 class="theme--tuktuk__title">{{$t('coupon.ShopDiscount')}}</h2>
      <span class="price">Rp{{ Number(data.payload.discount.amount)+Number(data.payload.automaticDiscount.amount) | g_number_format }}</span>
      <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--grey.svg" />
    </div>
    <div class="coupon-entry__selected" v-if="data.payload.discount.code">
      <p class="m-10">{{$t('coupon.Usedcoupons')}}</p>
      <p class="m-10">{{ data.payload.discount.code }}</p>  
      <div class="flex items-center">
        <div>
          <template v-if="data.payload.discount.discount_value.type === 'fixed'">
            <p class="m-0">{{$t('coupon.Discount')}} {{ 'RP' +Number(data.payload.discount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

          </template>
          <template v-else>
            <p class="m-0">{{$t('coupon.Discount')}} {{ data.payload.discount.discount_value.value ? data.payload.discount.discount_value.value + "% " : "" }}
            </p>
          </template>   
         <template v-if="data.payload.discount.minimum_requirements.type == 'minimum_purchase'">
            <p  class="m-0">{{ data.payload.discount.minimum_requirements.value ? `Minimum purchase of RP
              ${Number(data.payload.discount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
              $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <template v-else-if="data.payload.discount.minimum_requirements.type == 'minimum_quantity'">
            <p  class="m-0">{{ data.payload.discount.minimum_requirements.value ? `Minimum purchase of
              ${data.payload.discount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

            </template>
            <template v-else>
            <p  class="m-0">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <p class="m-0" v-if="data.payload.discount.end_date">                   
            {{ data.payload.discount.end_date ? moment(data.payload.discount.full_end_date).diff(moment(data.payload.discount.full_start_date), 'days')  > 0 ? "Until "+moment(data.payload.discount.full_end_date).diff(moment(data.payload.discount.full_start_date), 'days')+" days" : 'Until today' : '' }}
          </p>
        </div>
        <img @click="clearDiscount" role="button" src="@/assets/icons/ic-close.svg" alt="x">
      </div>     
    </div>
    <div class="coupon-entry__selected" v-if="data.payload.automaticDiscount.code">
      <p class="m-10">{{$t('coupon.Usedcoupons')}}</p>
      <p class="m-10">{{ data.payload.automaticDiscount.code }}</p>  
      <div class="flex items-center">
        <div>
          <template v-if="data.payload.automaticDiscount.discount_value.type === 'fixed'">
            <p class="m-0">{{$t('coupon.Discount')}} {{ 'RP' +Number(data.payload.automaticDiscount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

          </template>
          <template v-else>
            <p class="m-0">{{$t('coupon.Discount')}} {{ data.payload.automaticDiscount.discount_value.value ? data.payload.automaticDiscount.discount_value.value + "% " : "" }}
            </p>
          </template>   
         <template v-if="data.payload.automaticDiscount.minimum_requirements.type == 'minimum_purchase'">
            <p  class="m-0">{{ data.payload.automaticDiscount.minimum_requirements.value ? `Minimum purchase of RP
              ${Number(data.payload.automaticDiscount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
              $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <template v-else-if="data.payload.automaticDiscount.minimum_requirements.type == 'minimum_quantity'">
            <p  class="m-0">{{ data.payload.automaticDiscount.minimum_requirements.value ? `Minimum purchase of
              ${data.payload.automaticDiscount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

            </template>
            <template v-else>
            <p  class="m-0">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
          </template>
          <p class="m-0" v-if="data.payload.automaticDiscount.end_date">                   
            {{ data.payload.automaticDiscount.end_date ? moment(data.payload.automaticDiscount.full_end_date).diff(moment(data.payload.automaticDiscount.full_start_date), 'days')  > 0 ? "Until "+moment(data.payload.automaticDiscount.full_end_date).diff(moment(data.payload.automaticDiscount.full_start_date), 'days')+" days" : 'Until today' : '' }}
          </p>
        </div>      
      </div>     
    </div>
    <button @click="modal = true">{{$t('coupon.Lookforcoupons')}}</button>
    <SelectCoupon v-model="modal" theme="tuktuk" :discountLists="data.discountLists"  :payload="data.payload" :data="data" @selectDiscount="selectDiscount" @clearDiscountCode="clearDiscount"  @updateDiscountLists="updateDiscountLists"  :cartSummary="data.cartSummary"  :totalQuantity="data.totalQuantity" :totalPrice="data.totalPrice"   />

  
  </div>
</template>

<script>
import SelectCoupon from '../../../SelectCoupon.vue';
import moment from "moment";

export default {
  components: {
    SelectCoupon,
  },
  props: ["data"],
  data() {
    return {
      modal: false,
      moment
    }
  },
  methods: {
    selectDiscount(discount) {
      this.$emit('applyDiscount', discount ? discount.title : '')
    },
    clearDiscount(){
      this.$emit('clearDiscountCode')
    },
    applyDiscount(discount){
      this.$emit('applyDiscount',discount)
    },
    updateDiscountLists(discounts,replace){
      this.$emit('updateDiscountLists',discounts,replace)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-shop-discount {
  background-color: #FFF;
  margin-bottom: 8px;
  padding: 10px 15px;
  text-align: left;

  &>:not([hidden])~:not([hidden]) {
    margin-top: 16px !important;
    margin-bottom: 0px !important;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 4px;

    h2 {
      flex: 1 0 auto;
    }

    .price {
      color: #EB435B;
      font-size: 12px;
      font-weight: 500;
      font-family: TikTokDisplayMedium;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 100%;
    color: #F75656;
    background: #FFF;
    border: 1px solid #F75656;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: TikTokDisplayMedium;
  }

  .coupon-entry {
    &__selected {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 8px 10px;
      margin-top: 16px;
      background: #EB435B0D;
      border: 1px solid #EB435B26;
      border-radius: 4px;

      &>p {
        color: #15171C;
        margin: 0;
      }

      &>div>div {
        flex-grow: 1;
        p:first-child {
          color: #EB435B;
          font-size: 12px;
          font-weight: 700;
          font-family: TikTokDisplayBold;
        }
        p:nth-child(2) {
          opacity: 0.4;
          color: #15171C;
          font-size: 10px;
          font-weight: 400;
          font-family: TikTokDisplayRegular;
        }
      }

      [role=button] {
        cursor: pointer;
      }
    }
  }
}
</style>